
import { Vue, Options } from 'vue-class-component';
import uniqBy from 'lodash-es/uniqBy';
import {
  BaseButton,
  BaseChip,
  BaseSwitch,
  CheckboxGroup,
  ConditionSortableList,
  BaseModal,
  SearchSelect,
  ConditionForm
} from '@/lib/components';
import {
  AnatomyService,
  ConditionService,
  DiagnosisStatusService,
  EncounterService,
  PatientReviewDiagnosisService,
  PatientReviewIndicatorService,
  TechnicalIssueService
} from '@/services/api';
import {
  Anatomy,
  Condition,
  Diagnosis,
  DiagnosisRequest,
  DiagnosisStatus,
  FullIndicator,
  TechnicalIssue
} from '@/models';
import { Encounter } from '@/models/episode-of-care/encounter.model';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    encounterId: {
      type: String,
      required: true
    },
    patientId: {
      type: String,
      required: true
    }
  },
  components: {
    CheckboxGroup,
    BaseSwitch,
    ConditionForm,
    SearchSelect,
    BaseModal,
    ConditionSortableList,
    BaseButton,
    BaseChip
  }
})
export default class DiagnosisPage extends Vue {
  showConditionModal = false;
  isPresent = true;
  diagnosisEdit: Diagnosis | null = null;

  unableToDiagnose = false;
  showTechnicalIssues = false;
  selectedTechnicalIssues: Array<string> = [];
  isTechnicalIssuesLoaded = false;
  // data to fetch
  signs: Array<FullIndicator> = [];
  absentConditions: Array<Diagnosis> = [];
  presentConditions: Array<Diagnosis> = [];
  conditionList: Array<Condition> = [];
  anatomy: Array<Anatomy> = [];
  statuses: Array<DiagnosisStatus> = [];
  technicalIssues: Array<TechnicalIssue> = [];
  encounter!: Encounter;

  // props
  encounterId!: string;
  patientId!: string;

  // loading
  signsLoading = true;
  modalFieldsLoading = true;
  conditionsLoading = true;

  // services
  encounterService = new EncounterService();
  technicalIssueService = new TechnicalIssueService();
  conditionService = new ConditionService();
  anatomyService = new AnatomyService();
  statusService = new DiagnosisStatusService();
  diagnosisService = new PatientReviewDiagnosisService(this.patientId, this.encounterId);
  reviewIndicatorService = new PatientReviewIndicatorService(this.patientId, this.encounterId);
  notificationStore = useNotificationStore();

  nbItems = 10;

  get unableToDiagnoseOptions() {
    return this.technicalIssues.map((issue) => ({
      value: issue.id,
      label: issue.name
    }));
  }

  get conditionsBatch() {
    return this.conditionList.filter((value, index) => index < this.nbItems);
  }

  get conditions(): Array<Diagnosis> {
    return this.absentConditions.concat(this.presentConditions);
  }

  // Get uniq signs by indicator/anatomy
  get chips() {
    return uniqBy(this.signs, (v) => [v.indicator.id, v.anatomy ? v.anatomy.id : ''].join());
  }

  async created() {
    try {
      this.encounter = (await this.encounterService.fetch(this.encounterId));
      this.unableToDiagnose = this.encounter.extension?.unable_to_diagnose ?? false;
      this.technicalIssues = await this.fetchTechnicalIssues();
      this.isTechnicalIssuesLoaded = true;
      this.selectedTechnicalIssues = this.encounter.technical_issues.map((t) => t.id);
      if (this.selectedTechnicalIssues.length > 0) {
        this.showTechnicalIssues = true;
      }
      this.signs = await this.fetchSigns();
      this.signsLoading = false;
      const conditions = await this.fetchDiagnoses();
      this.onConditionsChange(conditions);
      this.conditionsLoading = false;
      this.conditionList = await this.fetchConditionList();
      this.anatomy = await this.fetchAnatomyList();
      this.statuses = await this.fetchStatuses();
      this.modalFieldsLoading = false;
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async fetchTechnicalIssues() {
    return await this.technicalIssueService.index();
  }

  async fetchSigns() {
    return (
      await this.reviewIndicatorService.index({
        params: {
          include: 'indicator,indicator.type,anatomy',
          'filter[indicator.type.name]': 'sign',
          sort: 'indicators.name'
        }
      })
    ).data;
  }

  async fetchDiagnoses() {
    return (
      await this.diagnosisService.index({
        params: {
          include: 'stage,status,anatomy,condition'
        }
      })
    ).data;
  }

  async fetchAnatomyList() {
    return (await this.anatomyService.index()).data;
  }

  async fetchConditionList(search = '') {
    return (
      await this.conditionService.index({
        params: {
          include: 'stages',
          'filter[search]': search
        }
      })
    ).data;
  }

  async fetchStatuses() {
    return (await this.statusService.index()).data;
  }

  async removeSign(id: string) {
    try {
      await this.reviewIndicatorService.delete(id);
      this.signs = [...this.signs].filter((sign) => sign.id !== id);
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.scans.sign.remove-error')
      });
    }
  }

  async createDiagnosis(diagnosis: DiagnosisRequest) {
    if (diagnosis) {
      try {
        this.showConditionModal = false;
        this.nbItems = 10;
        if (this.diagnosisEdit) {
          const response = await this.diagnosisService.update((this.diagnosisEdit as Diagnosis).id, diagnosis);
          if (this.diagnosisEdit.is_present) {
            this.presentConditions = this.presentConditions.filter(
              (condition) => condition.id !== (this.diagnosisEdit as Diagnosis).id
            );
          } else {
            this.absentConditions = this.absentConditions.filter(
              (condition) => condition.id !== (this.diagnosisEdit as Diagnosis).id
            );
          }
          response.is_present ? this.presentConditions.push(response) : this.absentConditions.push(response);
          this.diagnosisEdit = null;
        } else {
          const response = (await this.diagnosisService.create(diagnosis)).data;
          response.is_present ? this.presentConditions.push(response) : this.absentConditions.push(response);
        }
      } catch (e) {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.diagnosis.create-error')
        });
      }
    } else {
      this.cancel();
    }
  }

  async updateSearch(search: string) {
    // Maintain sort order and only add search param when non-empty
    this.conditionList = await this.fetchConditionList(search);
    this.nbItems = 10;
  }

  async deleteDiagnosis(id: string) {
    try {
      if (confirm(this.$t('custom.uhb.diagnosis.delete-message') as string)) {
        await this.diagnosisService.delete(id);
        this.absentConditions = this.absentConditions.filter((condition) => condition.id !== id);
        this.presentConditions = this.presentConditions.filter((condition) => condition.id !== id);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async setUnableToDiagnose(value: boolean) {
    try {
      this.unableToDiagnose = value;
      this.showTechnicalIssues = value;
      await this.encounterService.update(this.encounterId, {
        extension: {
          unable_to_diagnose: value
        }
      });
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.review.update-error'),
        label: this.$t('custom.uhb.diagnosis.unable-error')
      });
      this.unableToDiagnose = !value;
      this.showTechnicalIssues = !value;
    }
  }

  toggleTechnicalIssues() {
    this.showTechnicalIssues = true;
  }

  async onTechnicalIssuesChange(value: Array<string>) {
    const prevValue = this.selectedTechnicalIssues.slice();
    try {
      this.selectedTechnicalIssues = value;
      const technicalIssues = this.technicalIssues.filter((issue) => value.includes(issue.id));
      await this.encounterService.update(this.encounterId, {
        technical_issues: technicalIssues
      });
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.review.update-error'),
        label: this.$t('custom.uhb.diagnosis.technical-error')
      });
      this.selectedTechnicalIssues = prevValue;
    }
  }

  editDiagnosis(diagnosis: Diagnosis) {
    this.diagnosisEdit = diagnosis;
    this.isPresent = diagnosis.is_present;
    this.showConditionModal = true;
  }

  openConditionModal(presence: boolean) {
    this.isPresent = presence;
    this.showConditionModal = true;
  }

  onConditionsChange(conditions: Array<Diagnosis>) {
    this.absentConditions = conditions.filter((condition) => !condition.is_present);
    this.presentConditions = conditions.filter((condition) => condition.is_present);
  }

  cancel() {
    this.nbItems = 10;
    this.showConditionModal = false;
    this.diagnosisEdit = null;
  }

  loadNext() {
    this.nbItems += 10;
  }
}
