import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-16" }
const _hoisted_2 = { class: "flex justify-between mb-12 items-center" }
const _hoisted_3 = { class: "text-2xl font-bold text-gray-900" }
const _hoisted_4 = { class: "mt-1 text-lg text-gray-500" }
const _hoisted_5 = { class: "h-10 flex items-center px-4 shadow rounded-md border border-gray-200 bg-white space-x-2" }
const _hoisted_6 = { class: "space-y-8" }
const _hoisted_7 = { class: "p-8" }
const _hoisted_8 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_9 = { class: "flex flex-row flex-wrap mt-6 -mb-3" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "mx-2" }
const _hoisted_12 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_13 = { class: "text-gray-600" }
const _hoisted_14 = { class: "mt-6 space-y-4" }
const _hoisted_15 = {
  key: 0,
  class: "flex justify-center bg-gray-50 p-4 border border-gray-400 border-dashed rounded border-gray-500"
}
const _hoisted_16 = { class: "mt-12" }
const _hoisted_17 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_18 = { class: "text-gray-600" }
const _hoisted_19 = { class: "mt-6 space-y-4" }
const _hoisted_20 = {
  key: 0,
  class: "flex justify-center bg-gray-50 p-4 border border-dashed border-gray-400 rounded border-gray-500"
}
const _hoisted_21 = {
  key: 1,
  class: "bg-white border rounded p-8 mt-8"
}
const _hoisted_22 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_23 = { class: "text-gray-600" }
const _hoisted_24 = { class: "mt-6 space-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseChip = _resolveComponent("BaseChip")!
  const _component_ConditionSortableList = _resolveComponent("ConditionSortableList")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CheckboxGroup = _resolveComponent("CheckboxGroup")!
  const _component_ConditionForm = _resolveComponent("ConditionForm")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.diagnosis')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.instructions')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BaseSwitch, {
          "model-value": _ctx.unableToDiagnose,
          color: "success-500",
          "label-class": "leading-none text-sm mr-3 font-medium text-gray-500",
          label: _ctx.$t('custom.uhb.diagnosis.unable'),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setUnableToDiagnose($event)))
        }, null, 8, ["model-value", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.chips.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["bg-white border rounded", { 'opacity-50 cursor-wait pointer-events-none': _ctx.signsLoading }])
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('custom.uhb.scans.sign.signs')), 1),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chips, (sign) => {
                  return (_openBlock(), _createBlock(_component_BaseChip, {
                    key: sign.id,
                    class: "mb-3 mr-3",
                    removable: !sign.image_id,
                    onRemoved: ($event: any) => (_ctx.removeSign(sign.id))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        (sign.anatomy && sign.anatomy.name)
                          ? (_openBlock(), _createBlock(_component_MkBadge, {
                              key: 0,
                              size: "xsmall",
                              class: "rounded-full"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(sign.anatomy.name), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(sign.indicator.name), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["removable", "onRemoved"]))
                }), 128))
              ])
            ])
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.unableToDiagnose)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["p-8 rounded bg-white border border-gray-300", {
          'opacity-50 pointer-events-none': _ctx.conditionsLoading,
          'cursor-wait': _ctx.conditionsLoading,
          'cursor-not-allowed': _ctx.conditionsLoading
        }])
          }, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.conditions')), 1),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.add-message')), 1),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_ConditionSortableList, {
                modelValue: _ctx.presentConditions,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.presentConditions) = $event)),
                onDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteDiagnosis($event))),
                onEdit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editDiagnosis($event)))
              }, null, 8, ["modelValue"]),
              (_ctx.conditionList.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_BaseButton, {
                      color: "ghost",
                      "left-icon": "add",
                      loading: _ctx.modalFieldsLoading,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openConditionModal(true)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.diagnosis.add-condition')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.absences')), 1),
              _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.absences-add')) + "s ", 1),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_ConditionSortableList, {
                  modelValue: _ctx.absentConditions,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.absentConditions) = $event)),
                  onDelete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deleteDiagnosis($event))),
                  onEdit: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editDiagnosis($event)))
                }, null, 8, ["modelValue"]),
                (_ctx.conditionList.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createVNode(_component_BaseButton, {
                        color: "ghost",
                        "left-icon": "add",
                        loading: _ctx.modalFieldsLoading,
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openConditionModal(false)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.diagnosis.add-absence')), 1)
                        ]),
                        _: 1
                      }, 8, ["loading"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.isTechnicalIssuesLoaded)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (!_ctx.showTechnicalIssues && _ctx.selectedTechnicalIssues.length === 0)
              ? (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 0,
                  class: "mt-8 w-full border-dashed border-2 border-primary-700 bg-primary-100 text-primary-700 hover:text-white",
                  color: "primary",
                  size: "large",
                  "left-icon": "add",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.toggleTechnicalIssues()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.diagnosis.add-technical-issues')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.technical-issues')), 1),
                  _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t('custom.uhb.diagnosis.select-issues')), 1),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_CheckboxGroup, {
                      "model-value": _ctx.selectedTechnicalIssues,
                      options: _ctx.unableToDiagnoseOptions,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.onTechnicalIssuesChange($event)))
                    }, null, 8, ["model-value", "options"])
                  ])
                ]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showConditionModal)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          "close-on-background-click": false,
          class: "inline-block bg-white",
          onClose: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ConditionForm, {
              class: "w-screen max-w-3xl",
              anatomy: _ctx.anatomy,
              statuses: _ctx.statuses,
              "condition-list": _ctx.conditionsBatch,
              conditions: _ctx.conditions,
              "is-present": _ctx.isPresent,
              diagnosis: _ctx.diagnosisEdit,
              onSearch: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateSearch($event))),
              onCancel: _ctx.cancel,
              onCreate: _cache[12] || (_cache[12] = ($event: any) => (_ctx.createDiagnosis($event))),
              onLoadNext: _cache[13] || (_cache[13] = ($event: any) => (_ctx.loadNext()))
            }, null, 8, ["anatomy", "statuses", "condition-list", "conditions", "is-present", "diagnosis", "onCancel"])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ]))
}